import React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import { Col, Container, Row } from "react-bootstrap"

const PrivacyPolicy = () => (
  <Layout pageInfo={{ pageName: "privacy-policy", pageTitle: "Privacy Policy" }}>
    <Seo title="Privacy Policy"/>

    <section className="general-page" id="privacy-policy">
      <Container>
        <Row>
          <Col>
          </Col>
        </Row>
      </Container>
    </section>
  </Layout>
)

export default PrivacyPolicy
